import React, { useState, useRef } from "react";
import {
  Typography,
  Fab,
  Menu,
  ListItemIcon,
  ListItemText,
  List,
  Drawer,
  Toolbar,
  Box,
  ListItem,
  Container,
  Divider,
  useTheme,
  MenuList,
  ListItemButton,
} from "@mui/material";
import {
  LocalMallOutlined,
  HomeOutlined,
  FavoriteBorderOutlined,
  PersonOutline,
  AccountCircle,
} from "@mui/icons-material";
import { Link, Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useUserInfoStore } from "../lib/state";

const Account: React.FC = () => {
  const listItems = [
    // {
    //   name: "Profile",
    //   slug: "profile",
    //   icon: <PersonOutline />,
    // },
    {
      name: "Orders",
      slug: "orders",
      icon: <LocalMallOutlined />,
    },
    {
      name: "Favorites",
      slug: "favorites",
      icon: <FavoriteBorderOutlined />,
    },
    {
      name: "Addresses",
      slug: "addresses",
      icon: <HomeOutlined />,
    },
  ];

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  const user = useUserInfoStore((state) => state.user);

  return (
    <div className="p-4 flex">
      <Fab
        color="primary"
        aria-label="add"
        className="fixed bottom-14 right-7 sm:hidden"
        onClick={() => setOpen(!open)}
        ref={anchorRef}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
      >
        <AccountCircle />
      </Fab>
      <Menu
        id="simple-menu"
        className="sm:hidden"
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        keepMounted
        open={open}
      >
        <MenuList>
          <ListItem
            key="profile"
            component={Link}
            to={"/order/account"}
            className="flex items-center text-black"
          >
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          {listItems.map((item) => (
            <ListItem
              key={item.slug}
              component={Link}
              to={"/order/account/" + item.slug.toLowerCase()}
              className="flex items-center text-black"
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </MenuList>
      </Menu>
      <Box component="nav" className="min-w-[200px] h-full hidden sm:block">
        <Drawer variant="permanent" open>
          <Toolbar />
          <List className="flex flex-col min-w-[200px] max-w-[200px]">
            <ListItem
              key="back"
              component={Link}
              to={"/order/"}
              className="flex items-center text-black"
            >
              <ListItemIcon>
                <ArrowBackIcon className="text-black" />
              </ListItemIcon>
            </ListItem>
            <Divider />
            <ListItemButton
              key="profile"
              component={Link}
              to={"/order/account"}
              className="flex items-center text-black"
            >
              <ListItemIcon>
                <PersonOutline />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
            {listItems.map((item) => (
              <ListItemButton
                key={item.slug}
                component={Link}
                to={"/order/account/" + item.slug.toLowerCase()}
                className="flex items-center text-black"
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      </Box>

      <Container component="main" maxWidth="xl" className="mt-2">
        <Typography variant="h4">{user?.name ?? "Unknown"}</Typography>
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4">
          <Typography>+91 {user?.phone}</Typography>
          <Typography>{user?.email}</Typography>
        </div>
        <Divider className="my-4" />
        <Outlet />
      </Container>
    </div>
  );
};

export default Account;
