import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxl: true;
  }
}

const createMingTheme = (rootElement: HTMLElement | null) => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
    palette: {
      primary: {
        main: "#FF8936", // Tangerine yellow
      },
      secondary: {
        main: "#232B2B",
      },
      background: {
        default: "#fffefc", // Pearl white background
      },
    },
    typography: {
      fontFamily: '"Noto Sans", sans-serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollBehavior: "smooth",
          }
        },
      },

      // Ensure that tailwind can affect these elements
      // https://mui.com/material-ui/guides/interoperability/#tailwind-css:~:text=Change%20the%20target,the%20Tailwind%20config.
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });
};

export default createMingTheme;
