import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
  useMediaQuery,
  Divider,
  Tooltip,
  Dialog,
  DialogTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Order from "../lib/interfaces/Order";
import { formatPrice } from "../lib/utils";
import { OrderStatus } from "../lib/enums";
import { LocalShipping, Timelapse } from "@mui/icons-material";

// interface PastOrderDetails {
//   restaurantName: string;
//   location: string;
//   orderId: string;
//   orderDate: string;
//   deliveredDate: string;
//   items: string;
//   totalPaid: number;
//   imageUrl: string;
// }

interface PastOrderCardProps {
  orderDetails: Order;
}

const PastOrderCard = ({ orderDetails }: PastOrderCardProps) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const [open, setOpen] = useState(false);

  return (
    <>
      <Card sx={{ maxWidth: isSmallScreen ? 300 : 600, m: 2 }}>
        <CardContent>
          <div className="flex justify-between">
            <Typography variant="subtitle1" component="div">
              {orderDetails.address.addressName}
            </Typography>
            <Tooltip
              title={"Created: " + new Date(orderDetails.createdAt)}
              placement="top-start"
            >
              {orderDetails.status === OrderStatus.OrderDelivered ? (
                <Chip
                  className="mt-1"
                  icon={<CheckCircleIcon />}
                  label={`Delivered`}
                  color="success"
                  variant="outlined"
                  size="small"
                />
              ) : orderDetails.status === OrderStatus.OrderPlaced ? (
                <Chip
                  className="mt-1"
                  icon={<Timelapse />}
                  label={`Processing`}
                  color="info"
                  variant="outlined"
                  size="small"
                />
              ) : orderDetails.status === OrderStatus.OrderConfirmed ? (
                <Chip
                  className="mt-1"
                  icon={<Timelapse />}
                  label={`Preparing`}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              ) : orderDetails.status === OrderStatus.OrderShipping ? (
                <Chip
                  className="mt-1"
                  icon={<LocalShipping />}
                  label={`Shipping`}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              ) : (
                <></>
              )}
            </Tooltip>
          </div>
          <Typography variant="body2" color="text.secondary">
            ID: {orderDetails.id}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${new Date(orderDetails.createdAt).toDateString()}`}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${orderDetails.address.name}, ${orderDetails.address.phone}`}
          </Typography>
          <Divider className="my-2" />
          <Typography component="div" variant="body2">
            {orderDetails.items.map((x) => (
              <Typography key={x.id} className="text-sm">
                {x.quantity}x {x.name}
                {/* ({x.customizations.map((y) => y.name).join(", ")}) */}
              </Typography>
            ))}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body2" component="div">
            <Typography variant="body2" component="span" color="text.secondary">
              Total:
            </Typography>{" "}
            {formatPrice(orderDetails.price)}
          </Typography>
          <Button
            size="small"
            variant="outlined"
            className="ml-auto"
            onClick={() => setOpen(true)}
          >
            View Details
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Order Details</DialogTitle>
        <Card>
          <CardContent>
            <div className="flex justify-between">
              <Typography variant="subtitle1" component="div">
                {orderDetails.address.addressName}
              </Typography>
              <Tooltip
                title={"Created: " + new Date(orderDetails.createdAt)}
                placement="top-start"
              >
                <Chip
                  className="mt-1"
                  icon={<CheckCircleIcon />}
                  label={`Delivered`}
                  color="success"
                  variant="outlined"
                  size="small"
                />
              </Tooltip>
            </div>
            <Typography variant="body2" color="text.secondary">
              ID: {orderDetails.id}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`${new Date(orderDetails.createdAt).toDateString()}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`${orderDetails.address.name}, ${orderDetails.address.phone}`}
            </Typography>
            <Divider className="my-2" />
            <Typography component="div" variant="body2">
              {orderDetails.items.map((x) => (
                <Typography key={x.id} className="text-sm">
                  {x.quantity}x {x.name}(
                  {x.customizations.map((y) => y.name).join(", ")})
                </Typography>
              ))}
            </Typography>
          </CardContent>
          <CardActions>
            <Typography variant="body1" component="div">
              <Typography variant="body1">
                Subtotal: {formatPrice(orderDetails.originalPrice)}
              </Typography>
              <Typography variant="body1">
                Discount:{" "}
                {formatPrice(orderDetails.originalPrice - orderDetails.price)}
              </Typography>
              <Typography variant="body1">
                Total: {formatPrice(orderDetails.price)}
              </Typography>{" "}
            </Typography>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default PastOrderCard;
