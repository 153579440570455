import React from "react";
import {
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { CartSidebar, CategoryList } from "../components";
import axiosInstance from "../config/axios";
import { useQuery } from "@tanstack/react-query";
import SearchIcon from "@mui/icons-material/Search";
import { Menu } from "../lib/interfaces";
import ItemList from "../components/ItemList";
import { useOutletContext } from "react-router-dom";

const Order = () => {
  const menuQuery = useQuery<Menu>({
    queryKey: ["menu"],
    queryFn: () => axiosInstance.get<Menu>("/menu").then((res) => res.data),
  });

  const [setOpen] =
    useOutletContext<[React.Dispatch<React.SetStateAction<boolean>>]>();

  const categoriesRef = React.useRef<Array<HTMLElement | null>>([]);

  const scrollToCategory = (position: number) => {
    const categoryRef = categoriesRef.current[position];
    if (categoryRef) {
      const offset =
        categoryRef.getBoundingClientRect().top +
        window.scrollY -
        (window.document.getElementById("appnav")?.getBoundingClientRect()
          .bottom ?? 64);

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const [search, setSearch] = React.useState<string>("");
  const [foodType, setFoodType] = React.useState<string>("all");

  return (
    <>
      <div className="flex">
        {menuQuery.isPending ? (
          // loader in the center
          <div className="flex w-full place-content-center">
            <CircularProgress />
          </div>
        ) : menuQuery.error ? (
          <div>Error: {menuQuery.error.message}</div>
        ) : (
          <>
            <CategoryList
              categories={menuQuery.data.categories}
              scrollToCategory={scrollToCategory}
            />
            <Container component="main" maxWidth="xl" className="mt-2">
              <div className="flex place-content-center my-4">
                <TextField
                  className="w-full"
                  size="small"
                  placeholder="Search…"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <ToggleButtonGroup
                  exclusive
                  value={foodType}
                  aria-label="text alignment"
                  className="h-10 ml-1"
                  onChange={(_, value) => {
                    setFoodType(value);
                  }}
                >
                  <ToggleButton value="all">ALL</ToggleButton>
                  <ToggleButton value="veg">
                    <img src="/veg.png" alt="Vegetarian" className="h-6 w-6" />
                  </ToggleButton>

                  <ToggleButton value="nonveg">
                    <img
                      src="/non_veg.png"
                      alt="Non-vegetarian"
                      className="h-6 w-6"
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <ItemList
                menu={menuQuery.data}
                search={search}
                foodType={foodType}
                ref={categoriesRef}
              />
            </Container>
            <CartSidebar setOpen={setOpen} />
          </>
        )}
      </div>
    </>
  );
};

export default Order;
