enum OrderStatus {
  AwaitingPayment = 0,
  OrderPlaced = 1,
  OrderConfirmed = 2,
  OrderShipping = 3,
  OrderDelivered = 4,
  OrderCancelled = 5,
  OrderRefunded = 6,
}

export default OrderStatus;
