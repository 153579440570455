import React, { useRef, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Fab,
  Menu,
  MenuItem,
  Drawer,
  Toolbar,
  Box,
  Divider,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Category } from "../lib/interfaces";

interface CategoryListProps {
  categories: Category[];
  scrollToCategory: (position: number) => void;
}

const CategoryList = ({ categories, scrollToCategory }: CategoryListProps) => {
  const [selected, setSelected] = useState<string>(categories[0]?.slug);
  const [open, setOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (slug: string, position: number) => {
    setSelected(slug);
    scrollToCategory(position);
    if (open) setOpen(false);
  };

  const theme = useTheme();

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className="fixed bottom-14 right-7 sm:hidden"
        onClick={() => setOpen(!open)}
        ref={anchorRef}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
      >
        <MenuIcon />
      </Fab>
      <Menu
        id="simple-menu"
        className="sm:hidden"
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        keepMounted
        open={open}
      >
        <MenuList dense>
          <MenuItem
            disableRipple
            disableTouchRipple
            disableGutters
            className="place-content-center text-primary text-base"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            Categories
          </MenuItem>
          <Divider />
          {categories
            .sort((a, b) => a.position - b.position)
            .map((cat) => (
              <MenuItem
                key={cat.slug}
                onClick={() => handleClick(cat.slug, cat.position)}
              >
                <Typography variant="inherit" className="capitalize text-base">
                  {cat.name}
                </Typography>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
      <Box
        component="nav"
        className="min-w-[180px] md:min-w-[200px] lg:min-w-[230px] h-full hidden sm:block"
      >
        <Drawer variant="permanent" open>
          <Toolbar />
          <List
            dense
            className="flex flex-col min-w-[180px] max-w-[180px] md:min-w-[200px] md:max-w-[200px] lg:min-w-[230px] lg:max-w-[230px]"
          >
            <ListItem
              key="header"
              className="place-content-center text-primary text-lg"
            >
              Categories
            </ListItem>
            <Divider variant="middle" className="mb-2 border-primary" />
            {categories
              .sort((a, b) => a.position - b.position)
              .map((cat) => (
                <ListItem key={cat.slug}>
                  <ListItemButton
                    // disableRipple
                    // disableTouchRipple
                    selected={selected === cat.slug}
                    onClick={() => handleClick(cat.slug, cat.position)}
                    sx={{
                      width: "100%",
                      justifyContent: "flex-start",
                      "&.Mui-selected": {
                        backgroundColor:
                          "linear-gradient(90deg, rgba(255, 255, 255), rgba(255, 137, 54, 0.12))",
                        borderRight: "3px solid rgba(255, 137, 54)",
                      },
                    }}
                  >
                    <ListItemText className="uppercase" primary={cat.name} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default CategoryList;
