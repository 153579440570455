import { CartItem } from "./interfaces";

const formatPrice = (price: number) => {
  const rupees = price / 100;
  return rupees.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};

// Hashes a cart item to uniquely identify it
// Assumes that the customizations are sorted
const hashCartItem = (item: CartItem) => {
  return (
    item.id +
    "@" +
    item.customizations
      .map((x) => x.id)
      .sort()
      .join("+")
  );
};

export { formatPrice, hashCartItem };
