import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider, useTheme } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import Link from "@mui/material/Link";
import { Facebook, LinkedIn, X, YouTube } from "@mui/icons-material";

const Footer = () => {
  const theme = useTheme();

  return (
    <div
      className="bg-background relative"
      style={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Divider variant="fullWidth" className="border-[1px] border-gray-300" />
      <Grid
        container
        spacing={6}
        justifyContent="space-between"
        alignItems="start"
        className="py-3 px-3 md:py-12 md:px-6"
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            QUICK LINKS
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Button href="/" variant="text">
              Home
            </Button>
            <Button href="/about" variant="text">
              About
            </Button>
            <Button href="/locate-us" variant="text">
              Locate Us
            </Button>
            <Button href="/order" variant="text">
              Order Online
            </Button>
            <Button href="/terms" variant="text">
              Terms & Conditions
            </Button>
            <Button href="/privacy" variant="text">
              Privacy Policy
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            CONTACT US
          </Typography>
          <Typography>
            <b>Email:</b> mingchinese@gmail.com
          </Typography>
          <Typography>
            <b>Phone:</b>+91 0000000000
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            WE ARE SOCIAL
          </Typography>
          <div className="flex space-x-4">
            <Link href="https://facebook.com/" target="_blank">
              <Facebook />
            </Link>
            <Link
              href="https://instagram.com/mingchinese_mahim/"
              target="_blank"
            >
              <InstagramIcon />
            </Link>
            <Link href="https://linkedin.com/" target="_blank">
              <LinkedIn />
            </Link>
            <Link href="https://x.com/" target="_blank">
              <X />
            </Link>
            <Link href="https://youtube.com/" target="_blank">
              <YouTube />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
