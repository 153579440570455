import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Container,
  Typography,
  Divider,
  List,
  ListItem,
  TextField,
  Chip,
  Select,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useCartStore, useUserInfoStore } from "../lib/state";
import CartListItem from "../components/CartListItem";
import { formatPrice } from "../lib/utils";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../config/axios";
import { Cart } from "../lib/interfaces";
import { Place } from "@mui/icons-material";
import useRazorpay from "react-razorpay";

interface Coupon {
  code: string | null;
}

interface Err {
  message: string;
}

const Checkout = () => {
  const [cart, setCart, clearCart] = useCartStore((state) => [state.cart, state.setCart, state.clearCart]);
  const [user, loadingUser] = useUserInfoStore((state) => [
    state.user,
    state.loading,
  ]);

  const [couponText, setCouponText] = React.useState<string>(cart.coupon ?? "");

  const applyCoupon = useMutation<Coupon, Err, Coupon>({
    mutationFn: async (coupon) => {
      const req = await axiosInstance.post("/cart/apply_coupon", coupon);
      return req.data as Coupon;
    },
  });

  useEffect(() => {
    const req = async () => {
      const data = await axiosInstance.get<Cart>("/cart");

      if (data.data) {
        setCart(data.data, true);
      }
    };

    if (user && !loadingUser) {
      req();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyCoupon.data]);

  const [address, setAddress] = React.useState<number | null>(null);
  const [selectOpen, setSelectOpen] = React.useState<boolean>(false);

  const [Razorpay] = useRazorpay();

  const [loading, setLoading] = React.useState<boolean>(false);

  const nav = useNavigate();

  const placeOrder = async () => {
    if (!user) return;
    if (!loadingUser && !user) {
      return;
    }
    if (cart.items.length === 0 || address === null) return;

    setLoading(true);

    const req = await axiosInstance.post("/orders", {
      address: user.addresses[address],
    });

    console.log(req.data);

    if (req.status === 200) {
      const options = {
        key: "rzp_test_220RGGkVS04tdo",
        amount: cart.price.toString(),
        currency: "INR",
        name: "Ming Chinese",
        description: "Order Payment",
        image: "https://www.mingchinese.in/pic350.jpg",
        order_id: req.data.razorpay.id,
        handler: async (response: any) => {
          // console.log(response);
          if (response.razorpay_payment_id) {
            const confirmation = await axiosInstance.post(`/orders/${req.data.id}/confirm`, {
              orderId: response.razorpay_order_id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
            });

            if (confirmation.status === 204) {
              clearCart();
              nav("/order/account/orders");
            }
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phone,
        },
        notes: {
          address: user.addresses[address].addressName,
        },
        theme: {
          color: "#FF8936",
        },
      };

      const rpz = new Razorpay(options);
      rpz.on("payment.failed", (response: any) => {
        console.log(response);
      });
      rpz.open();
    }

    setLoading(false);
  };

  return (
    <>
      <Container component="main" maxWidth="xl" className="mt-8 mb-12">
        <Link to="/order" className="no-underline">
          <Button variant="text" startIcon={<ArrowBackIcon />}>
            Back to menu
          </Button>
        </Link>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8} className="flx">
            <Paper
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <Typography className="text-lg font-semibold px-4 my-2">
                Cart
              </Typography>
              <Divider className="border-primary" />
              <List
                dense
                className="flex flex-col lg:max-h-[50vh] overflow-y-scroll"
              >
                {cart.items.map((item, i) => {
                  return <CartListItem key={i} item={item} i={i} />;
                })}
                {cart.items.length === 0 && (
                  <ListItem>
                    <Typography className="" variant="body2">
                      Your cart is empty
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Paper>
            <Paper
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "10px",
                marginTop: "24px",
              }}
            >
              <Typography className="text-lg font-semibold px-4 my-2">
                Address
              </Typography>
              <Divider className="border-primary" />
              <div className="p-4">
                {user?.addresses && user.addresses.length > 0 ? (
                  <>
                    <Select
                      fullWidth
                      variant="outlined"
                      className=""
                      value={address}
                      open={selectOpen}
                      onOpen={() => setSelectOpen(true)}
                      onClose={() => setSelectOpen(false)}
                      startAdornment={<Place className="text-slate-300" />}
                    >
                      {user.addresses.map((address, i) => (
                        <option key={i} value={i}>
                          <ListItemButton
                            onClick={() => {
                              setAddress(i);
                              setSelectOpen(false);
                            }}
                          >
                            <ListItemText
                              primary={address.addressName}
                              secondary={`${address.house}, ${address.street}, ${address.city} - ${address.pincode}`}
                            />
                          </ListItemButton>
                        </option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>
                    <Typography className="text-sm text-gray-800">
                      You don't have any saved addresses. Add one to continue.
                    </Typography>
                    <Link to="/order/account/addresses">
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Place />}
                        className="mt-4"
                      >
                        Add Address
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Paper
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography className="text-lg font-semibold px-4 my-2">
                    Apply Coupon
                  </Typography>
                  <Divider className="border-primary" />
                  <TextField
                    fullWidth
                    value={couponText}
                    onChange={(e) => setCouponText(e.target.value)}
                    placeholder="Enter Coupon Code"
                    label="Coupon Code"
                    variant="outlined"
                    margin="normal"
                    disabled={
                      cart.coupon !== undefined || applyCoupon.isPending
                    }
                  />
                  <Button
                    fullWidth
                    variant="outlined"
                    className="mt-1 mb-2"
                    color={cart.coupon !== undefined ? "error" : "primary"}
                    disabled={applyCoupon.isPending || couponText === ""}
                    onClick={() => {
                      if (cart.coupon !== undefined) {
                        applyCoupon.mutate({ code: null });
                      } else {
                        applyCoupon.mutate({ code: couponText });
                      }
                    }}
                  >
                    {cart.coupon !== undefined ? "Remove" : "Apply"}
                  </Button>
                  {applyCoupon.isError && (
                    <Typography className="text-red-500 text-sm">
                      Coupon code is invalid
                    </Typography>
                  )}
                </Paper>
              </Grid>

              <Grid item>
                <Paper
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    marginTop: "12px",
                  }}
                >
                  <Typography className="text-lg font-semibold px-4 my-2">
                    Order Summary
                  </Typography>
                  <Divider className="border-primary" />
                  <Typography className="flex justify-between px-4">
                    <Typography>Subtotal:</Typography>
                    <Typography>{formatPrice(cart.originalPrice)}</Typography>
                  </Typography>
                  <Typography className="flex justify-between px-4">
                    <Typography>Discount:</Typography>
                    <Typography component="div">
                      {cart.coupon !== undefined && (
                        <Chip
                          size="small"
                          label={cart.coupon}
                          color="primary"
                          className="mr-1 text-white"
                        />
                      )}
                      {formatPrice(
                        cart.originalPrice -
                          cart.price +
                          (cart.couponDiscount ?? 0)
                      )}
                    </Typography>
                  </Typography>
                  <Typography className="flex justify-between px-4">
                    <Typography>GST (18%):</Typography>
                    <Typography>
                      {formatPrice((cart.price / 100) * 18)}
                    </Typography>
                  </Typography>
                  <Typography className="flex justify-between px-4">
                    <Typography>Total:</Typography>
                    <Typography>{formatPrice(cart.price)}</Typography>
                  </Typography>

                  <Button
                    fullWidth
                    variant="contained"
                    className="mt-4 mb-2"
                    disabled={
                      cart.items.length === 0 || address === null || loading
                    }
                    onClick={placeOrder}
                  >
                    {loading ? "Placing Order..." : "Place Order"}
                  </Button>
                  <Typography className="mb-2" style={{ fontSize: "small" }}>
                    Total price is inclusive of GST and other taxes.
                  </Typography>
                  <div className="flex flex-row">
                    <PriorityHighIcon sx={{ fontSize: "medium" }} />
                    <Typography style={{ fontSize: "small" }}>
                      Orders once placed cannot be cancelled and are
                      non-refundable.
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Checkout;
