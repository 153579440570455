import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Typography,
} from "@mui/material";
import { auth, createRecaptchaVerifier } from "../config/firebase";
import { signInWithPhoneNumber, ConfirmationResult } from "firebase/auth";
import axios from "../config/axios";
import { Link } from "react-router-dom";
import { useUserInfoStore } from "../lib/state";

type LoginDialogProps = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

type DialogProps = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  setSignUp: (signUp: boolean) => void;
};

const SignUpDialog = ({
  phoneNumber,
  setPhoneNumber,
  setSignUp,
}: DialogProps) => {
  const [onStep2, setOnStep2] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [otp, setOtp] = useState("");

  const [confirmation, setConfirmation] = useState<ConfirmationResult | null>(
    null
  );

  const setUser = useUserInfoStore((state) => state.setUser);

  const handleSignUp = async () => {
    try {
      const res = await axios.post("/auth/check_phone", { phone: phoneNumber });

      if (res.data.exists) {
        // user already exists
        setSignUp(false);
      } else {
        setOnStep2(true);
        await handleFirebaseLogin();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFirebaseLogin = async () => {
    const verifier = createRecaptchaVerifier((_resp) => {});

    await signInWithPhoneNumber(auth, "+91" + phoneNumber, verifier)
      .then((confirmationResult) => {
        setConfirmation(confirmationResult);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleVerification = () => {
    if (!confirmation) return;

    confirmation
      .confirm(otp)
      .then(async (cred) => {
        const token = await cred.user.getIdToken();
        const signupRes = await axios.post(
          "/auth/signup",
          {
            phone: phoneNumber,
            name: name,
            email: email,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (signupRes.status === 201) {
          setUser(signupRes.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <DialogTitle>{onStep2 ? "Enter OTP" : "Sign Up"}</DialogTitle>
      <DialogContent className={onStep2 ? "" : "-mt-4"}>
        {!onStep2 && (
          <DialogContentText className="mb-4" fontSize={14}>
            <Button
              className="normal-case p-0 text-sm hover:underline"
              onClick={() => setSignUp(false)}
            >
              or login to your account.
            </Button>
          </DialogContentText>
        )}
        {onStep2 ? (
          <TextField
            fullWidth
            name="otp"
            label="OTP"
            required
            type="text"
            size="small"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        ) : (
          <>
            <TextField
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              required
              type="tel"
              size="small"
              value={phoneNumber}
              className="mb-2"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextField
              fullWidth
              name="name"
              label="Name"
              required
              size="small"
              value={name}
              className="my-2"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              name="email"
              label="Email"
              required
              size="small"
              value={email}
              className="mt-2"
              onChange={(e) => setEmail(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions className="flex justify-center pt-0">
        {onStep2 ? (
          <Button
            className="w-[90%]"
            variant="contained"
            onClick={handleVerification}
            type="submit"
          >
            Verify
          </Button>
        ) : (
          <Button
            // Do not remove, required for firebase recaptcha to work
            id="sign-in-button"
            className="w-[90%]"
            variant="contained"
            onClick={handleSignUp}
            type="submit"
            disabled={!phoneNumber || !name || !email}
          >
            Continue
          </Button>
        )}
      </DialogActions>
      <div className="flex justify-center my-3">
        <Typography className="text-xs text-slate-600 max-w-[85%]">
          By creating an account, I accept the{" "}
          <Link to="/terms" className="no-underline text-black">
            Terms & Conditions
          </Link>{" "}
          &{" "}
          <Link to="/privacy" className="no-underline text-black">
            Privacy Policy
          </Link>
        </Typography>
      </div>
    </>
  );
};

const SignInDialog = ({
  phoneNumber,
  setPhoneNumber,
  setSignUp,
}: DialogProps) => {
  const [onStep2, setOnStep2] = useState(false);
  const [otp, setOtp] = useState("");

  const [confirmation, setConfirmation] = useState<ConfirmationResult | null>(
    null
  );

  const handleLogin = async () => {
    try {
      const res = await axios.post("/auth/check_phone", { phone: phoneNumber });

      if (res.data.exists) {
        setOnStep2(true);
        await handleFirebaseLogin();
      } else {
        setSignUp(true);
      }
    } catch (error) {
      // assume invalid phone number
      console.log(error);
    }
  };

  const handleFirebaseLogin = async () => {
    const verifier = createRecaptchaVerifier((_resp) => {});

    await signInWithPhoneNumber(auth, "+91" + phoneNumber, verifier)
      .then((confirmationResult) => {
        // console.log(confirmationResult);
        setConfirmation(confirmationResult);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleVerification = () => {
    if (!confirmation) return;

    confirmation
      .confirm(otp)
      .then((user) => {
        console.log(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <DialogTitle>{onStep2 ? "Enter OTP" : "Login"}</DialogTitle>
      <DialogContent className={onStep2 ? "-mt-4 pt-4" : "-mt-4"}>
        {!onStep2 && (
          <DialogContentText className="mb-4" fontSize={14}>
            <Button
              className="normal-case p-0 text-sm hover:underline"
              onClick={() => setSignUp(true)}
            >
              or create an account.
            </Button>
          </DialogContentText>
        )}
        {onStep2 ? (
          <TextField
            fullWidth
            name="otp"
            label="OTP"
            required
            type="text"
            size="small"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        ) : (
          <TextField
            fullWidth
            name="phoneNumber"
            label="Phone Number"
            required
            type="tel"
            size="small"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions className="flex justify-center pt-0">
        {onStep2 ? (
          <Button
            className="w-[90%] mx-0"
            variant="contained"
            onClick={handleVerification}
            type="submit"
          >
            Login
          </Button>
        ) : (
          <Button
            // Do not remove, required for firebase recaptcha to work
            id="sign-in-button"
            className="w-[90%] mx-0"
            variant="contained"
            onClick={handleLogin}
            type="submit"
          >
            Login
          </Button>
        )}
      </DialogActions>
      <div className="flex justify-center my-3">
        <Typography className="text-xs text-slate-600 max-w-[85%]">
          By clicking on Login, I accept the{" "}
          <Link to="/tos" className="no-underline text-black">
            Terms & Conditions
          </Link>{" "}
          &{" "}
          <Link to="/privacy" className="no-underline text-black">
            Privacy Policy
          </Link>
        </Typography>
      </div>
    </>
  );
};

const LoginDialog = ({ isOpen, setOpen }: LoginDialogProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [signUp, setSignUp] = useState(false);

  const user = useUserInfoStore((state) => state.user);

  useEffect(() => {
    if (user) {
      setOpen(false);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dialog open={isOpen} onClose={() => setOpen(false)}>
      {signUp ? (
        <SignUpDialog
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setSignUp={setSignUp}
        />
      ) : (
        <SignInDialog
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setSignUp={setSignUp}
        />
      )}
    </Dialog>
  );
};

export default LoginDialog;
