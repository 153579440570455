import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDyu76HoltWz_-SMCoDiI0JFOr0mVZRLs0",
  authDomain: "ming-online.firebaseapp.com",
  projectId: "ming-online",
  storageBucket: "ming-online.appspot.com",
  messagingSenderId: "531571600010",
  appId: "1:531571600010:web:88542c07cfbf19f2ea1b73",
  measurementId: "G-8XHXW2ZMCN",
};

const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);

const createRecaptchaVerifier = (callback: (resp: any) => void) => {
  return new RecaptchaVerifier(auth, "sign-in-button", {
    size: "invisible",
    callback: callback,
  });
};

export {
  analytics,
  auth,
  createRecaptchaVerifier,
  firebaseConfig,
  db,
  storage,
};
