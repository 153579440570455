import React from "react";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { Toolbar } from "@mui/material";

const Wrapper = () => (
  <div className="flex min-h-screen flex-col">
    <div className="flex-initial">
      <Navbar />
      {/* Toolbar adds enough space to push content below fixed nav */}
      <Toolbar />
    </div>
    <div className="flex-1">
      <Outlet />
    </div>
    <div className="flex-initial">
      <Footer />
    </div>
  </div>
);

export default Wrapper;
