import React from "react";

const Favorties = () => {
  return (
    <div>
      <h1>Favourites</h1>
    </div>
  );
};

export default Favorties;
