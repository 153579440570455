import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        placeContent: "center",
      }}
    >
      <img
        className="mb-4"
        src="/logo512.png"
        alt="Logo"
        width={128}
        height={128}
      />
      <Typography sx={{ marginBottom: "8px" }} variant="h4">
        <Typography variant="h4" display={"inline"}>
          404
        </Typography>{" "}
        - Page Not Found
      </Typography>
      <Typography sx={{ marginBottom: "4px" }}>
        Looks like you aren't supposed to be here.
      </Typography>
      <Link to={"/"}>
        <Typography>Click here to go to the main page.</Typography>
      </Link>
    </div>
  );
}

export default NotFound;
