import React, { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ItemCustomizationDialog from "./ItemCustomizationDialog";
import { CartItem, Item } from "../lib/interfaces";
import { useCartStore } from "../lib/state";
import ItemRemoveDialog from "./ItemRemoveDialog";

interface AddButtonWithCounterProps {
  item?: Item;
  cartItem?: CartItem;
}

const AddButtonWithCounter = ({
  item,
  cartItem,
}: AddButtonWithCounterProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const [cart, addItem, increaseQuantity, decreaseQuantity] = useCartStore(
    (state) => [
      state.cart,
      state.addItem,
      state.increaseQuantity,
      state.decreaseQuantity,
    ]
  );

  // items with quantity
  const count = cartItem
    ? cartItem.quantity
    : item
    ? cart.items.reduce((acc, i) => {
        if (i.id === item.id) {
          return acc + i.quantity;
        }
        return acc;
      }, 0)
    : 0;

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAdd = () => {
    if (cartItem) {
      increaseQuantity(cartItem);
    } else if (item) {
      if (item.customizations.length > 0) {
        setOpenDialog(true);
      } else {
        addItem(item, 1, []);
      }
    }
  };

  const handleRemove = () => {
    if (cartItem) {
      decreaseQuantity(cartItem);
    } else if (item) {
      if (item.customizations.length > 0) {
        const items = cart.items.filter((i) => i.id === item.id);
        if (items.length > 1) {
          setRemoveDialogOpen(true);
        } else {
          decreaseQuantity(items[0]);
        }
      } else {
        decreaseQuantity({
          id: item.id,
          name: item.name,
          price: item.price,
          originalPrice: item.originalPrice,
          quantity: 1,
          customizations: [],
          available: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
        {count > 0 && (
          <IconButton
            onClick={handleRemove}
            size="small"
            style={{ marginRight: "8px" }}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAdd}
          style={{
            borderRadius: "20px",
            padding: "4px 12px",
            fontSize: "0.875rem",
            textTransform: "none",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            minWidth: "0",
          }}
        >
          {count === 0 ? (
            <>
              <AddShoppingCartIcon
                fontSize="small"
                style={{ marginRight: "8px" }}
              />
              Add
            </>
          ) : (
            <>
              <AddShoppingCartIcon
                fontSize="small"
                style={{ marginRight: "4px" }}
              />
              {count}
            </>
          )}
        </Button>
      </div>
      {item && openDialog && (
        <ItemCustomizationDialog
          open={openDialog}
          handleClose={handleDialogClose}
          item={item}
        />
      )}
      {item && removeDialogOpen && (
        <ItemRemoveDialog
          open={removeDialogOpen}
          handleClose={() => setRemoveDialogOpen(false)}
          item={item}
        />
      )}
    </>
  );
};

export default AddButtonWithCounter;
