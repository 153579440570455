import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
// import AddButtonWithCounter from "./CounterButton";
import { formatPrice } from "../lib/utils";
import { useCartStore, useUserInfoStore } from "../lib/state";
import { useNavigate } from "react-router-dom";
import CartListItem from "./CartListItem";

interface CartSidebarProps {
  setOpen: (open: boolean) => void;
}

const CartSidebar = ({ setOpen }: CartSidebarProps) => {
  const cart = useCartStore((state) => state.cart);
  const nav = useNavigate();

  const user = useUserInfoStore((state) => state.user);

  const handleCheckout = () => {
    if (user) {
      nav("/order/checkout");
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="relative min-w-[320px] xl:min-w-[350px] hidden lg:block">
      <Box className="min-w-[300px]">
        <Drawer
          anchor="right"
          sx={{ minWidth: "300px" }}
          variant="permanent"
          open
        >
          <Toolbar />
          <List
            dense
            className="flex flex-col min-w-[320px] max-w-[320px] xl:min-w-[350px] xl:max-w-[350px]"
          >
            <ListItem
              key="header"
              className="place-content-center text-primary text-lg"
            >
              Cart Preview
            </ListItem>
            <Divider variant="middle" className="mb-2 border-primary" />
            {cart ? (
              <>
                {cart.items.map((item, i) => {
                  return <CartListItem key={i} item={item} i={i} />;
                })}
                {cart.items.length === 0 && (
                  <ListItem>
                    <Typography className="" variant="body2">
                      Your cart is empty
                    </Typography>
                  </ListItem>
                )}

                <Divider variant="middle" className="border-primary" />

                <ListItem>
                  <Typography component="div" className="text-base mr-auto">
                    {cart.originalPrice !== cart.price && (
                      <Typography className="mr-1 text-xs text-center">
                        Subtotal
                      </Typography>
                    )}
                    Total
                  </Typography>
                  <Typography component="div" className="text-base">
                    {cart.originalPrice !== cart.price && (
                      <Typography className="line-through mr-1 text-xs text-center">
                        {formatPrice(cart.originalPrice)}
                      </Typography>
                    )}
                    {formatPrice(cart.price)}
                  </Typography>
                </ListItem>
              </>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
            <ListItem className="flex place-content-center">
              <Tooltip
                placement="bottom"
                title={cart.valid ? "Checkout" : "Remove Invalid Cart Items"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!cart.valid}
                  onClick={handleCheckout}
                >
                  Checkout
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </div>
  );
};

export default CartSidebar;
