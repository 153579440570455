import React, { useRef } from "react";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  AppBar,
  Box,
  Container,
  useTheme,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import LoginDialog from "./Login";
import LoginIcon from "@mui/icons-material/Login";
import { useCartStore, useUserInfoStore } from "../lib/state";
import { auth } from "../config/firebase";
import { ShoppingCart } from "@mui/icons-material";

const settings = [
  {
    name: "Account",
    url: "/account",
  },
];

interface OrderNavbarProps {
  setIsLoginOpen: (open: boolean) => void;
  isLoginOpen: boolean;
}

const OrderNavbar = ({ isLoginOpen, setIsLoginOpen }: OrderNavbarProps) => {
  const user = useUserInfoStore((state) => state.user);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const cart = useCartStore((state) => state.cart);
  const navigate = useNavigate();

  const openUserMenu = () => {
    setOpen(true);
  };

  const closeUserMenu = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await auth.signOut();
    setOpen(false);
  };

  const openLoginDialog = () => {
    setIsLoginOpen(true);
  };

  const itemCount = cart.items.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        className="bg-[#EA0008] max-h-16"
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <Container sx={{ minWidth: "100%" }}>
          <Toolbar disableGutters>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src="/banner.png"
                alt="Ming Chinese"
                className="h-[50px]"
              ></img>
            </Link>
            <Box sx={{ flexGrow: 1, display: "flex" }} />
            <Tooltip
              placement="bottom"
              title={cart.valid ? "Cart" : "Remove Invalid Cart Items"}
            >
              <IconButton
                aria-label="cart"
                className="mr-4"
                disabled={!cart.valid}
                onClick={() => {
                  if (user) {
                    if (cart.valid) {
                      navigate("/order/checkout");
                    }
                  } else {
                    setIsLoginOpen(true);
                  }
                }}
              >
                <Badge
                  badgeContent={itemCount}
                  color="primary"
                  className="text-white"
                  sx={{
                    "& .MuiBadge-badge": {
                      right: -6,

                      padding: "0 4px",
                    },
                  }}
                >
                  <ShoppingCart className="text-white" />
                </Badge>
              </IconButton>
            </Tooltip>
            {user ? (
              <IconButton ref={anchorRef} onClick={openUserMenu}>
                <Avatar>{user.name.at(0)}</Avatar>
              </IconButton>
            ) : (
              <>
                {isLoginOpen && (
                  <LoginDialog isOpen={isLoginOpen} setOpen={setIsLoginOpen} />
                )}
                <Button
                  startIcon={<LoginIcon />}
                  className="mr-[0.5vw] text-white border-white"
                  variant="outlined"
                  onClick={openLoginDialog}
                >
                  Login
                </Button>
              </>
            )}

            <Menu
              className="mt-[45px] no-underline"
              id="menu-appbar"
              anchorEl={anchorRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={closeUserMenu}
            >
              {settings.map((setting) => (
                <Link
                  key={setting.name}
                  className="no-underline text-[unset] text-center"
                  to={"/order" + setting.url}
                >
                  <MenuItem
                    className="no-underline text-[unset] text-center"
                    onClick={closeUserMenu}
                  >
                    <Typography>{setting.name}</Typography>
                  </MenuItem>
                </Link>
              ))}
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography>Logout</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default OrderNavbar;
