import React from "react";
import { Box, CardActions, ListItem, Typography } from "@mui/material";
import { formatPrice } from "../lib/utils";
import { CartItem } from "../lib/interfaces";
import { useCartStore } from "../lib/state";
import AddButtonWithCounter from "./CounterButton";

interface CartListItemProps {
  item: CartItem;
  i: number;
}

const CartListItem = ({ item, i }: CartListItemProps) => {
  const cart = useCartStore((state) => state.cart);

  return (
    <ListItem key={i}>
      <Box
        className="w-full border-slate-200 border-0 border-b-2"
        sx={{
          borderStyle: i === cart.items.length - 1 ? "none" : "solid",
        }}
      >
        <CardActions>
          <Typography component="div" className="mr-auto">
            <Typography variant="h6" component="div" className="text-base">
              <Typography
                component="span"
                className={item.available ? "text-slate-900" : "text-red-500"}
              >
                {item.name}
              </Typography>
              <Typography
                component="span"
                className="text-sm text-slate-600 ml-2"
              >
                x{item.quantity}
              </Typography>
            </Typography>
            {item.customizations && (
              <ul className="pl-3 list-disc">
                {item.customizations.map((customization, i) => (
                  <Typography
                    key={i}
                    component="li"
                    className="text-xs text-slate-600"
                  >
                    {customization.name}
                  </Typography>
                ))}
              </ul>
            )}
          </Typography>
          <Typography component="div" className="text-sm text-center">
            {item.originalPrice !== item.price && (
              <Typography className="line-through mr-1 text-xs text-center">
                {formatPrice(item.originalPrice)}
              </Typography>
            )}
            {formatPrice(item.price)}
            <AddButtonWithCounter cartItem={item} />
          </Typography>
        </CardActions>
      </Box>
    </ListItem>
  );
};

export default CartListItem;
