import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  CardActions,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddButtonWithCounter from "./CounterButton"; // Assuming this is correctly implemented.
import { Category, Item, Menu } from "../lib/interfaces";
import { formatPrice } from "../lib/utils";
import { FoodType } from "../lib/enums";
import { FavoriteBorder } from "@mui/icons-material";

interface ItemListProps {
  menu: Menu;
  search: string;
  foodType: string;
}

interface CategoryProps {
  category: Category;
  items: Item[];
}

const CategoryContainer = ({ category, items }: CategoryProps) => {
  return (
    <>
      <Typography variant="h5" className="mt-2">
        {category.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {category.description}
      </Typography>
      <Divider className="mb-4 mt-2" />
      <Grid container spacing={2}>
        {items.map((item) => (
          <Grid item gridAutoColumns="1fr" key={item.id} className="max-w-full">
            <Card className="flex mb-4 h-[130px] max-h-[150px] max-w-full border-[1px] border-solid border-[rgba(0,0,0,0.12)]">
              <div className="relative w-[90px] sm:w-[130px]">
                <div className="absolute top-0 left-0 w-full h-full z-50">
                  <IconButton aria-label="add to favorites" className="p-1">
                    <FavoriteBorder className="text-white" />
                  </IconButton>
                </div>
                <CardMedia
                  component="img"
                  className="w-[90px] sm:w-[130px] object-cover absolute top-0 left-0 h-full"
                  image={item.images[0]}
                  alt={item.name}
                />
                {item.foodType === FoodType.Vegetarian ? (
                  <img
                    src="/veg.png"
                    alt=""
                    className="absolute bottom-0 right-0 h-5 w-5 mr-1"
                  />
                ) : (
                  <img
                    src="/non_veg.png"
                    alt=""
                    className="absolute bottom-0 right-0 h-5 w-5 mr-1"
                  />
                )}
              </div>

              <CardContent className="py-1 sm:py-2 max-w-full">
                <Tooltip title={item.name} placement="top">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="text-lg sm:text-xl w-[60vw] sm:w-[250px] text-nowrap overflow-hidden"
                    textOverflow="ellipsis"
                  >
                    {item.name}
                  </Typography>
                </Tooltip>
                {/* <Tooltip title={item.description} placement="top"> */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="w-[60vw] sm:w-[250px] text-nowrap overflow-hidden"
                  textOverflow="ellipsis"
                >
                  {item.description}
                </Typography>
                {/* </Tooltip> */}
                <CardActions className="pb-0 px-0">
                  <Typography className="mr-auto">
                    {item.originalPrice !== item.price && (
                      <Typography
                        component="span"
                        className="line-through mr-1"
                      >
                        {formatPrice(item.originalPrice)}
                      </Typography>
                    )}
                    {formatPrice(item.price)}
                  </Typography>
                  <AddButtonWithCounter item={item} />
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const ItemList = forwardRef<Array<HTMLElement | null>, ItemListProps>(
  ({ menu, search, foodType }: ItemListProps, ref) => {
    const categoriesRef = React.useRef<Array<HTMLElement | null>>([]);
    const itemsSorted = new Map<string, Item[]>();

    useImperativeHandle(ref, () => categoriesRef.current, [categoriesRef]);

    // https://stackoverflow.com/a/56063129
    useEffect(() => {
      categoriesRef.current = categoriesRef.current.slice(
        0,
        menu.categories.length
      );
    }, [menu]);

    const filtered = () => {
      const items = menu.items;

      return items.filter((item) => {
        let valid = true;

        switch (foodType) {
          case "veg":
            valid = item.foodType === FoodType.Vegetarian;
            break;
          case "nonveg":
            valid =
              item.foodType === FoodType.NonVegetarian ||
              item.foodType === FoodType.ContainEgg;
            break;
        }

        if (search.trim() === "") return valid;

        return (
          (item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.description?.toLowerCase().includes(search.toLowerCase())) &&
          valid
        );
      });
    };

    filtered().forEach((item) => {
      const category = item.category;
      if (itemsSorted.has(category)) {
        itemsSorted.get(category)?.push(item);
      } else {
        itemsSorted.set(category, [item]);
      }
    });

    return (
      <>
        {itemsSorted.size > 0 ? (
          menu.categories
            .filter((category) => itemsSorted.has(category.slug))
            .sort((a, b) => a.position - b.position)
            .map((category) => (
              <div
                key={category.slug}
                ref={(el) => (categoriesRef.current[category.position] = el)}
              >
                <CategoryContainer
                  category={category}
                  items={itemsSorted.get(category.slug) ?? []}
                />
              </div>
            ))
        ) : (
          <>
            <Typography variant="h5" className="mt-2">
              No Items Found
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Try searching for something else.
            </Typography>
          </>
        )}
      </>
    );
  }
);

export default ItemList;
