import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Box,
  Container,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const pages = ["About", "Locate Us", "Order"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const theme = useTheme();

  function handleOpenNavMenu(event: any) {
    setAnchorElNav(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        className="bg-[#EA0008]"
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
        id="appnav"
      >
        <Container sx={{ minWidth: "100%" }}>
          <Toolbar disableGutters>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src="/banner.png"
                alt="Ming Chinese"
                className="h-[50px]"
              ></img>
            </Link>
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            ></Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="md:hidden"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Link to={"/"} style={{ textDecoration: "none", color: "unset" }}>
                <MenuItem key={"/"} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
              </Link>

              {pages.map((page) => (
                <Link
                  to={"/" + page.replaceAll(" ", "-").toLowerCase()}
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                marginRight: "15vw",
              }}
            >
              <Link
                key={"/"}
                to={"/"}
                style={{
                  textDecoration: "none",
                  color: "unset",
                  maxHeight: "64px",
                }}
              >
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    margin: 0,
                  }}
                >
                  Home
                </Button>
              </Link>
              {pages.map((page) => (
                <Link
                  key={page}
                  to={"/" + page.replaceAll(" ", "-").toLowerCase()}
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    maxHeight: "64px",
                  }}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "white",
                      margin: 0,
                    }}
                  >
                    {page}
                  </Button>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Navbar;
