import {
    Button,
    CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
} from "@mui/material";
import React from "react";
import { Item } from "../lib/interfaces";
import { useCartStore } from "../lib/state";
import CartListItem from "./CartListItem";

interface ItemRemoveDialogProps {
  open: boolean;
  handleClose: () => void;
  item: Item;
}

const ItemRemoveDialog = ({
  open,
  handleClose,
  item,
}: ItemRemoveDialogProps) => {
  const cart = useCartStore((state) => state.cart);

  const items = cart.items.filter((i) => i.id === item.id);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Remove {item.name}</DialogTitle>
      <DialogContent>
        <List dense className="flex flex-col max-h-[65vh] overflow-y-scroll">
          {items.map((item, i) => (
            <CartListItem key={i} item={item} i={i} />
          ))}
        </List>
      </DialogContent>
      <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0 16px 16px 16px",
          }}
        >
          {/* <Typography variant="h6">{formatPrice(item.price)}</Typography> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Confirm
          </Button>
          </CardActions>
    </Dialog>
  );
};

export default ItemRemoveDialog;
