import React from "react";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import Link from "@mui/material/Link";

const Locate = () => {
  return (
    <>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.552878537442!2d72.84243227599352!3d19.039414253118473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c92c65bdc5cb%3A0xe998bde5bd87fd6e!2sMing%20Chinese%20Restaurant!5e0!3m2!1sen!2sin!4v1714762794170!5m2!1sen!2sin"
          // width="600"
          height="450"
          title="Ming Chinese Restaurant"
          style={{ border: "0", width: "100%" }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
     
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
  <Box sx={{
    padding: 4,
    backgroundColor: "#f0f8ff",
    width: {
      xs: "100%", 
      sm: "50%"   
    }
  }}>
    <Typography variant="h4" gutterBottom>
      Find Us
    </Typography>
    <Typography variant="body1">
      Alpaiwalla Building, <br />
      Mia Mohd Chhotani Rd, <br />
      Bethany Co-Operative Housing Society, <br />
      Mahim West, Mahim, Mumbai, <br />
      Maharashtra 400016
    </Typography>
    <Typography variant="body1" gutterBottom>
      +91 0000000000
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      mingchinese@gmail.com
    </Typography>
    <Link href="https://maps.app.goo.gl/NcaRZD8zXGXrvRmy8" underline="none">
    <Button variant="contained" color="primary">
      Get Directions
    </Button>
    </Link>
  </Box>
  <Box sx={{
    padding: 4,
    backgroundColor: "#f0f8ff",
    width: {
      xs: "100%",  
      sm: "50%"   
    }
  }}>
    <Typography variant="h4" gutterBottom>
      Feedback
    </Typography>
    <TextField
      fullWidth
      label="Your Name"
      variant="outlined"
      margin="normal"
    />
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Phone Number" variant="outlined" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="E-mail" variant="outlined" />
      </Grid>
    </Grid>
    <TextField
      fullWidth
      label="Message"
      variant="outlined"
      margin="normal"
      multiline
      rows={4}
    />
    <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
      Submit
    </Button>
  </Box>
</div>
    </>
  );
};

export default Locate;
