import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { Cart, CartItem, CartItemCustomization, Item } from "../interfaces";
import { hashCartItem } from "../utils";
import { ItemCustomizationType } from "../enums";

const EmptyCart: Cart = {
  items: [],
  originalPrice: 0,
  price: 0,
  valid: true,
  couponDiscount: 0,
};

type LocalCartStore = {
  synced: boolean;
  cart: Cart;
  setCart: (cart: Cart, synced: boolean) => void;
  clearCart: () => void;
  increaseQuantity: (cartItem: CartItem, count?: number) => void;
  decreaseQuantity: (cartItem: CartItem, count?: number) => void;
  addItem: (
    item: Item,
    quantity: number,
    customizations: CartItemCustomization[]
  ) => void;
  removeItem: (itemId: string) => void;
};

const useCartStore = create<LocalCartStore>()(
  persist(
    immer((set) => ({
      synced: false,
      cart: EmptyCart,
      setCart: (cart: Cart, synced: boolean) =>
        set((state) => {
          state.cart = cart;
          state.synced = synced;
        }),
      clearCart: () =>
        set((state) => {
          state.cart = EmptyCart;
          state.synced = false;
        }),
      increaseQuantity: (cartItem: CartItem, count: number = 1) =>
        set((state) => {
          const existingItem = state.cart.items.find(
            (i) => hashCartItem(i) === hashCartItem(cartItem)
          );

          if (existingItem) {
            const ogPrice = Number.parseInt(
              (existingItem.originalPrice / existingItem.quantity).toString()
            );
            const price = Number.parseInt(
              (existingItem.price / existingItem.quantity).toString()
            );

            existingItem.quantity += count;
            existingItem.originalPrice = ogPrice * existingItem.quantity;
            existingItem.price = price * existingItem.quantity;

            state.cart.originalPrice = state.cart.items.reduce(
              (acc, item) => acc + item.originalPrice,
              0
            );
            state.cart.price = state.cart.items.reduce(
              (acc, item) => acc + item.price,
              0
            );

            state.synced = false;
          }
        }),
      decreaseQuantity: (cartItem: CartItem, count: number = 1) =>
        set((state) => {
          const existingItem = state.cart.items.find(
            (i) => hashCartItem(i) === hashCartItem(cartItem)
          );

          if (existingItem) {
            const ogPrice = Number.parseInt(
              (existingItem.originalPrice / existingItem.quantity).toString()
            );
            const price = Number.parseInt(
              (existingItem.price / existingItem.quantity).toString()
            );

            existingItem.quantity -= count;
            existingItem.originalPrice = ogPrice * existingItem.quantity;
            existingItem.price = price * existingItem.quantity;

            if (existingItem.quantity <= 0) {
              state.cart.items = state.cart.items.filter(
                (i) => hashCartItem(i) !== hashCartItem(cartItem)
              );
            }

            state.cart.originalPrice = state.cart.items.reduce(
              (acc, item) => acc + item.originalPrice,
              0
            );
            state.cart.price = state.cart.items.reduce(
              (acc, item) => acc + item.price,
              0
            );

            state.synced = false;
          }
        }),
      addItem: (
        item: Item,
        quantity: number,
        customizations: CartItemCustomization[]
      ) =>
        set((state) => {
          const cartItem: CartItem = {
            id: item.id,
            name: item.name,
            quantity: quantity,
            customizations: customizations,
            originalPrice: item.originalPrice * quantity,
            price: item.price * quantity,
            available: true,
          };

          const hash = hashCartItem(cartItem);

          const existingItem = state.cart.items.find(
            (i) => hashCartItem(i) === hash
          );

          if (existingItem) {
            existingItem.quantity += quantity;
            existingItem.originalPrice =
              item.originalPrice * existingItem.quantity;
            existingItem.price = item.price * existingItem.quantity;
          } else {
            if (customizations.length > 0) {
              for (let i = 0; i < customizations.length; i++) {
                const customization = customizations[i];
                const parts = customization.id.split(":");
                const custom = item.customizations.find(
                  (c) => c.slug === parts[0]
                );

                if (!custom) {
                  return;
                }

                switch (custom.type) {
                  case ItemCustomizationType.Single: {
                    cartItem.price += (custom.price ?? 0) * quantity;
                    cartItem.originalPrice += (custom.price ?? 0) * quantity;
                    break;
                  }
                  case ItemCustomizationType.Radio: {
                    const option = custom.options.find(
                      (o) => o.slug === parts[1]
                    );

                    if (!option) {
                      return;
                    }

                    cartItem.price += (option.price ?? 0) * quantity;
                    cartItem.originalPrice += (option.price ?? 0) * quantity;
                    break;
                  }
                  case ItemCustomizationType.Multiple: {
                    const options = parts[1].split(",");
                    const optionPrices = custom.options.filter((o) =>
                      options.includes(o.slug)
                    );

                    cartItem.price += optionPrices.reduce(
                      (acc, o) => acc + (o.price ?? 0),
                      0
                    );
                    cartItem.originalPrice += optionPrices.reduce(
                      (acc, o) => acc + (o.price ?? 0),
                      0
                    );
                    break;
                  }
                }
              }
            }

            state.cart.items.push(cartItem);
          }

          state.cart.originalPrice = state.cart.items.reduce(
            (acc, item) => acc + item.originalPrice,
            0
          );
          state.cart.price = state.cart.items.reduce(
            (acc, item) => acc + item.price,
            0
          );

          state.synced = false;
        }),
      removeItem: (itemId: string) => {},
    })),
    {
      name: "cart",
      // Only persist the cart object
      partialize: (state) => ({ cart: state.cart }),
    }
  )
);

export { useCartStore };
