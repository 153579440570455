import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  CardActions,
} from "@mui/material";
import {
  CartItemCustomization,
  Item,
} from "../lib/interfaces";
import { ItemCustomizationType } from "../lib/enums";
import { formatPrice } from "../lib/utils";
import { useCartStore } from "../lib/state";

interface ItemCustomizationDialogProps {
  open: boolean;
  handleClose: () => void;
  item: Item;
}

const ItemCustomizationDialog = ({
  open,
  handleClose,
  item,
}: ItemCustomizationDialogProps) => {
  const [customizations, setCustomizations] = useState<string[]>(
    item.customizations.map(() => "")
  );

  const handleCustomizationChange = (index: number, value: string) => {
    switch (item.customizations[index].type) {
      case ItemCustomizationType.Multiple:
        const vals =
          customizations[index] === "" ? [] : customizations[index].split(",");
        if (vals.includes(value)) {
          vals.splice(vals.indexOf(value), 1);
        } else {
          vals.push(value);
        }
        customizations[index] = vals.join(",");
        break;
      default:
        if (customizations[index] === value) {
          customizations[index] = "";
        } else {
          customizations[index] = value;
        }
        break;
    }
    setCustomizations([...customizations]);
  };

  const disabled = customizations.some(
    (c, i) => item.customizations[i].required && c === ""
  );

  const addItem = useCartStore((state) => state.addItem);

  const handleAddToCart = () => {
    const customizationsData: CartItemCustomization[] = [];

    for (let i = 0; i < customizations.length; i++) {
      const customization = item.customizations[i];

      if (customizations[i] === "") {
        if (customization.required) {
          return;
        }
        continue;
      }

      switch (item.customizations[i].type) {
        case ItemCustomizationType.Single: {
          customizationsData.push({
            id: customization.slug,
            name: customization.name,
          });
          break;
        }
        case ItemCustomizationType.Radio: {
          const name = customization.options.find(
            (option) => option.slug === customizations[i]
          )?.name;
          customizationsData.push({
            id: `${customization.slug}:${customizations[i]}`,
            name: `${customization.name}: ${name}`,
          });
          break;
        }
        case ItemCustomizationType.Multiple: {
          const sorted = customizations[i].split(",").sort().join(",");
          const name = customization.options
            .filter((option) => sorted.includes(option.slug))
            .map((option) => option.name)
            .join(", ");
          customizationsData.push({
            id: `${customization.slug}:${sorted}`,
            name: `${customization.name}: ${name}`,
          });
          break;
        }
      }
    }

    addItem(item, 1, customizationsData);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Customize {item.name}</DialogTitle>
      <DialogContent className="max-h-[65vh] overflow-y-scroll">
        {item.customizations.map((customization, i) => (
          <>
            {customization.type === ItemCustomizationType.Single ? (
              <>
                <FormControlLabel
                  key={i}
                  value={customization.slug}
                  control={
                    <Checkbox
                      checked={customizations[i] !== ""}
                      onChange={() =>
                        handleCustomizationChange(i, customization.slug)
                      }
                    />
                  }
                  label={
                    <Typography component="div">
                      {customization.name}
                      {customization.price !== 0 && (
                        <Typography component="span" className="text-sm ml-6">
                          {formatPrice(customization.price)}
                        </Typography>
                      )}
                    </Typography>
                  }
                />
              </>
            ) : customization.type === ItemCustomizationType.Multiple ? (
              <>
                <Typography gutterBottom variant="subtitle1">
                  <b>{customization.name}</b>{" "}
                  {customization.required && (
                    <span className="text-red-500">*</span>
                  )}
                </Typography>
                {customization.options && (
                  <FormGroup>
                    {customization.options.map((option, j) => (
                      <FormControlLabel
                        key={j}
                        value={option.slug}
                        control={
                          <Checkbox
                            checked={customizations[i]
                              .split(",")
                              .includes(option.slug)}
                            onChange={() =>
                              handleCustomizationChange(i, option.slug)
                            }
                          />
                        }
                        label={
                          <Typography component="div">
                            {option.name}
                            {option.price !== 0 && (
                              <Typography
                                component="span"
                                className="text-sm ml-6"
                              >
                                {formatPrice(option.price)}
                              </Typography>
                            )}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                )}
              </>
            ) : customization.type === ItemCustomizationType.Radio ? (
              <>
                <Typography gutterBottom variant="subtitle1">
                  <b>{customization.name}</b>{" "}
                  {customization.required && (
                    <span className="text-red-500">*</span>
                  )}
                </Typography>
                {customization.options && (
                  <FormGroup>
                    <RadioGroup>
                      {customization.options.map((option, j) => (
                        <FormControlLabel
                          key={j}
                          value={option.slug}
                          control={
                            <Radio
                              checked={customizations[i] === option.slug}
                              onChange={() =>
                                handleCustomizationChange(i, option.slug)
                              }
                            />
                          }
                          label={
                            <Typography component="div">
                              {option.name}
                              {option.price !== 0 && (
                                <Typography
                                  component="span"
                                  className="text-sm ml-6"
                                >
                                  {formatPrice(option.price)}
                                </Typography>
                              )}
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormGroup>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ))}
        <Divider />
      </DialogContent>
      <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0 16px 16px 16px",
          }}
        >
          {/* <Typography variant="h6">{formatPrice(item.price)}</Typography> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToCart}
            disabled={disabled}
          >
            Add To Cart
          </Button>
          </CardActions>
    </Dialog>
  );
};

export default ItemCustomizationDialog;
