import React, { useEffect } from "react";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import OrderNavbar from "./OrderNavbar";
import { Toolbar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Cart } from "../lib/interfaces";
import axiosInstance from "../config/axios";
import { AxiosError, isAxiosError } from "axios";
import { hashCartItem } from "../lib/utils";
import { useCartStore, useUserInfoStore } from "../lib/state";

const OrderingWrapper = () => {
  const [open, setOpen] = React.useState(false);
  const [localCart, synced, setCart] = useCartStore((state) => [
    state.cart,
    state.synced,
    state.setCart,
  ]);

  const cartQuery = useQuery<Cart>({
    queryKey: ["cart"],
    queryFn: async () => {
      try {
        if (localCart.items.length === 0) {
          const req = await axiosInstance.get<Cart>("/cart");
          return req.data;
        }

        const req = await axiosInstance.put<Cart>(
          "/cart/items",
          localCart.items
        );
        return req.data;
      } catch (e: any | unknown) {
        if (isAxiosError(e)) {
          const err = e as AxiosError;
          if (err.response?.data) {
            const data = err.response.data as { message: string };
            if (data.message === "Validation Error") {
              const errData = data as {
                message: string;
                errors: { message: string; path: string }[];
              };

              const items: string[] = [];

              errData.errors.forEach((e) => {
                const idx = Number.parseInt(e.path.split(".")[0]);
                items.push(hashCartItem(localCart.items[idx]));
              });

              const newItems = localCart.items.filter((i) => {
                return !items.includes(hashCartItem(i));
              });

              const newCart = {
                ...localCart,
                items: newItems,
              };

              return newCart;
            }
          }
        }
      }

      return localCart;
    },
    enabled: false,
  });

  const [loading, user] = useUserInfoStore((state) => [
    state.loading,
    state.user,
  ]);

  useEffect(() => {
    if (user && !loading && !synced) {
      cartQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCart, loading, user]);

  useEffect(() => {
    if (cartQuery.data) {
      setCart(cartQuery.data, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartQuery.data]);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex-initial">
        <OrderNavbar isLoginOpen={open} setIsLoginOpen={setOpen} />
        {/* Toolbar adds enough space to push content below fixed nav */}
        <Toolbar />
      </div>
      <div className="flex-1">
        <Outlet context={[setOpen]} />
      </div>
      <div className="flex-initial">
        <Footer />
      </div>
    </div>
  );
};

export default OrderingWrapper;
