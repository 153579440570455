import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { User } from "../interfaces";

type UserInfo = {
  id: string;
  phone: string;
  name: string;
  email: string;
};

type UserStore = {
  user?: User;
  loading: boolean;
  setUser: (user?: User) => void;
  setLoading: (loading: boolean) => void;
  updateUser: (user: User, update: Partial<User>) => void;
};

const useUserInfoStore = create<UserStore>()(
  persist(
    immer((set) => ({
      user: undefined,
      // TODO: Use skeletons while loading
      loading: true,
      setUser: (user?: User) =>
        set((state) => {
          state.user = user;
          state.loading = false;
        }),
      setLoading: (loading: boolean) =>
        set((state) => {
          state.loading = loading;
        }),
      updateUser: (user: User, update: Partial<User>) =>
        set((state) => {
          state.user = { ...user, ...update };
        }),
    })),
    {
      name: "user",
      // Only persist the user object
      partialize: (state) => ({ user: state.user }),
    }
  )
);

export { useUserInfoStore };
export type { UserInfo };
