import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { useUserInfoStore } from "../lib/state";
import axiosInstance from "../config/axios";

interface AddAddressDialogProps {
  open: boolean;
  handleClose: () => void;
}

const AddAddressDialog = ({ open, handleClose }: AddAddressDialogProps) => {
  const [loadingUser, user, setUser] = useUserInfoStore((state) => [
    state.loading,
    state.user,
    state.setUser,
  ]);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(user?.name || "");
  const [phone, setPhone] = useState(user?.phone || "");

  const [addressName, setAddressName] = useState("");
  const [house, setHouse] = useState("");
  const [street, setStreet] = useState("");
  const [landmark, setLandmark] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [city, _setCity] = useState("Mumbai");
  const [pincode, setPincode] = useState("");

  const handleCreate = async () => {
    if (!user) return;
    if (!loadingUser && !user) {
      return;
    }

    setLoading(true);
    const req = await axiosInstance.patch("/users/@me", {
      addresses: [
        ...user!.addresses,
        { name, phone, addressName, house, street, landmark, city, pincode },
      ],
    });

    if (req.status === 200) {
      setUser(req.data);
    }
    setLoading(false);

    handleClose();
  };

  const disabled =
    loading ||
    !name ||
    !phone ||
    !addressName ||
    !house ||
    !street ||
    !city ||
    !pincode ||
    pincode.length !== 6 ||
    isNaN(Number(pincode)) ||
    !pincode.startsWith("400");

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Add Address</DialogTitle>
        <DialogContent className="pt-2 pb-0">
          <TextField
            size="small"
            label="Name"
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            label="Phone"
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            size="small"
            label="Address Name"
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={addressName}
            onChange={(e) => setAddressName(e.target.value)}
          />
          <TextField
            size="small"
            label="House/Flat No."
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={house}
            onChange={(e) => setHouse(e.target.value)}
          />
          <TextField
            size="small"
            label="Street/Locality"
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
          <TextField
            size="small"
            label="Landmark"
            variant="outlined"
            fullWidth
            className="mb-4"
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
          />
          <div className="flex space-x-2">
            <TextField
              size="small"
              label="City"
              variant="outlined"
              fullWidth
              className="mb-4"
              required
              value={city}
              disabled
            />
            <TextField
              size="small"
              label="Pincode"
              variant="outlined"
              fullWidth
              required
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              error={
                pincode !== "" &&
                (pincode.length !== 6 ||
                  isNaN(Number(pincode)) ||
                  !pincode.startsWith("400"))
              }
            />
          </div>
        </DialogContent>
        <DialogActions className="pb-4 px-6">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Tooltip
            title={
              disabled ? "Enter valid details to create an address" : "Create"
            }
            placement="top"
          >
            <Button
              variant="contained"
              disabled={disabled}
              onClick={handleCreate}
            >
              Create
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAddressDialog;
