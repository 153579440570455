import React from "react";
import { PastOrderCard } from "../../components";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Order from "../../lib/interfaces/Order";
import axiosInstance from "../../config/axios";
import { useUserInfoStore } from "../../lib/state";

const Orders = () => {
  const orders = useQuery<Order[]>({
    queryKey: ["orders"],
    queryFn: async () => {
      const response = await axiosInstance.get<Order[]>("/orders/@me");
      return response.data;
    },
    enabled: false,
  });

  const [loading, user] = useUserInfoStore((state) => [
    state.loading,
    state.user,
  ]);

  React.useEffect(() => {
    if (user && !loading) {
      orders.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);

  return (
    <Grid container spacing={2} className="h-[45rem] overflow-y-auto">
      {orders.isPending ? (
        <>
          <CircularProgress />
        </>
      ) : orders.isError ? (
        <>
          <Typography variant="h6" color="error">
            Error fetching orders
          </Typography>
        </>
      ) : orders.data?.length === 0 ? (
        <>
          <Typography>No orders available...</Typography>
        </>
      ) : (
        <>
          {orders.data?.map((order) => (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={order.id}>
              <PastOrderCard orderDetails={order} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default Orders;
