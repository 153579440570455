// Addresses.tsx

import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Place } from "@mui/icons-material";
import { AddAddressDialog } from "../../components";
import { useUserInfoStore } from "../../lib/state";
import axiosInstance from "../../config/axios";

const Addresses = () => {
  const [loadingUser, user, setUser] = useUserInfoStore((state) => [
    state.loading,
    state.user,
    state.setUser,
  ]);
  const [open, setOpen] = React.useState(false);

  const handleDelete = async (i: number) => {
    if (!user) return;
    if (!loadingUser && !user) {
      return;
    }

    const newAddresses = user.addresses.filter((_, idx) => idx !== i);

    const req = await axiosInstance.patch("/users/@me", {
      addresses: newAddresses,
    });

    if (req.status === 200) {
      setUser(req.data);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Place />}
        className="mb-4"
        onClick={() => setOpen(true)}
      >
        Add Address
      </Button>
      {user && (
        <>
          <Grid container spacing={2} className="h-[45rem] overflow-y-auto">
            {user.addresses.map((address, idx) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                <Card variant="outlined" style={{ maxWidth: 345 }}>
                  <CardContent>
                    <Typography gutterBottom>
                      <IconButton edge="start" aria-label="home" disabled>
                        <HomeIcon />
                      </IconButton>
                      {address.addressName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {address.house}, {address.street}, {address.city} -{" "}
                      {address.pincode}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      className="ml-auto"
                      size="small"
                      color="error"
                      onClick={() => handleDelete(idx)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          {open && (
            <AddAddressDialog open={open} handleClose={() => setOpen(false)} />
          )}
        </>
      )}
    </>
  );
};

export default Addresses;
