import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  ImageList,
  ImageListItem,
  Box,
  Grid,
} from "@mui/material";
import { LinearProgress } from "@mui/material";

const overlayStyles =
  "relative w-full h-[90vh] flex justify-center items-center bg-black bg-opacity-50 ";

const contentStyles = "text-center text-black ";

type ItemData = {
  img: string;
  title: string;
  author: string;
  rows: number;
  cols: number;
  featured: boolean;
  progress: number; // Add this line
};

const itemData: ItemData[] = [
  {
    img: "https://images.unsplash.com/photo-1519624213695-6819a985fb0b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Vegetable Rice",
    author: "Lorem ipsum dolor sit amet",
    rows: 2,
    cols: 2,
    featured: true,
    progress: 30,
  },
  {
    img: "https://images.unsplash.com/photo-1585032226651-759b368d7246?q=80&w=1984&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Hakka Noodles",
    author: "Lorem ipsum dolor sit amet",
    rows: 2,
    cols: 2,
    featured: true,
    progress: 25,
  },
  {
    img: "https://images.unsplash.com/photo-1565557623262-b51c2513a641?q=80&w=1971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Masala Chicken & Naan",
    author: "Lorem ipsum dolor sit amet",
    rows: 2,
    cols: 2,
    featured: true,
    progress: 45,
  },
  {
    img: "https://images.unsplash.com/photo-1603894584373-5ac82b2ae398?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDR8fHJpY2UlMjBhbmQlMjBncmF2eXxlbnwwfHwwfHx8MA%3D%3D",
    title: "Butter Chicken",
    author: "Lorem ipsum dolor sit amet",
    rows: 2,
    cols: 2,
    featured: true,
    progress: 20,
  },
];

const Index = () => {
  return (
    <div>
      {/* First background image */}
      <div className={overlayStyles}>
        <div className="relative w-full h-full">
          <div className="absolute inset-0 bg-cover bg-no-repeat blur-[2px] bg-[url('../public/assets/imgs/food2.jpg')]"></div>
          <div
            className={
              contentStyles +
              "flex flex-col justify-center items-center text-center h-full"
            }
          >
            <h1 className="z-30 text-white mb-0">
              Indo-Chinese food at its best only at Ming Chinese
            </h1>
            <p className="z-30 text-white mt-0">
              Come and take a dive into the journey of mouthwatering food
            </p>
            <Link to="/order">
              <Button className="z-30 text-2xl hover-none">Order Online</Button>
            </Link>
          </div>
        </div>
      </div>

      {/* Second background image */}
      <div className={overlayStyles}>
        <div className=" h-full bg-cover bg-no-repeat bg-[url('https://images.unsplash.com/photo-1495195129352-aeb325a55b65?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]">
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 md:mt-[10%] md:ml-[5%]">
              <img
                src="https://images.unsplash.com/photo-1464500542410-1396074bf230?q=80&w=1923&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="w-full mt-4 md:mt-0 md:mb-4 md:ml-4 md:mr-4 px-4 rounded-[10%]"
                alt="food"
              />
            </div>
            <div className="flex-1 md:mt-60 md:ml-12 md:mr-12 text-center">
              <h1 >A Little About Us</h1>
              <p><b>
              "Welcome to Ming Chinese, where flavors intertwine to create an unforgettable 
              dining experience! Nested in the heart of Mahim, Mumbai, Ming Chinese proudly 
              blends the aromatic spices of Indian cuisine with the traditional techniques of 
              Chinese cooking. Our restaurant is a vibrant celebration of Indo-Chinese 
              fusion, where every dish tells a story of culinary craftsmanship and cultural harmony.
              "</b>
              </p>
              <Link to="/order">
                <Button className="focus:outline-dashed rounded-lg text-xl">
                  Order Online
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Third background image */}
      <Box className="relative w-full mb-0 min-h-[90vh] bg-cover bg-no-repeat bg-[url('../public/assets/imgs/background_middle.jpg')]">
        <Grid container spacing={2}>
          {/* Images Grid */}
          <Grid item xs={12} lg={6} className="flex justify-center">
            <ImageList
              className="max-w-[80%] lg:max-w-full px-4 h-auto"
              cols={2}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img} sx={{ position: "relative" }}>
                  <img src={item.img} alt={item.title} loading="lazy" />
                  <div className="absolute bottom-0 left-0 right-0 p-2 ">
                    <div className="text-white font-bold">{item.title}</div>
                    <LinearProgress
                      variant="determinate"
                      value={item.progress}
                      sx={{
                        width: "80%",
                        mt: 1,
                        backgroundColor: "transparent",
                        color: "primary",
                        marginTop: "none",
                      }}
                    />
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>

          {/* Text Grid */}
          <Grid
            item
            xs={12}
            lg={6}
            className="flex flex-col justify-center items-center p-4"
          >
            <div className="px-4 h-auto">
              <h2 className="mb-0 text-center">Food Always Hot & Fresh</h2>
              <p className="mt-0 text-center">
                {" "}
                At Ming Chinese, freshness is our promise and passion. 
                We believe that great food starts with the finest ingredients, 
                which is why we source only the freshest produce and spices 
                from trusted suppliers. From the crisp vegetables to 
                every component of our dishes is carefully selected to ensure premium quality 
                and exceptional taste. What truly sets us apart is our commitment to crafting everything 
                in-house daily. Our skilled chefs pour their expertise and dedication into each dish, 
                preparing everything from scratch to guarantee unparalleled freshness and flavor. With 
                every bite, you can taste the difference that our dedication to quality makes, making dining 
                at Ming Chinese a truly remarkable experience.
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Index;
