import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useUserInfoStore } from "../../lib/state";
import axiosInstance from "../../config/axios";
import { Edit } from "@mui/icons-material";

const Profile = () => {
  const [user, setUser] = useUserInfoStore((state) => [
    state.user,
    state.setUser,
  ]);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!user) return;
    if (!loading && !user) {
      return;
    }

    if (!name || !email) {
      return;
    }

    setLoading(true);
    const req = await axiosInstance.patch("/users/@me", {
      name,
      email,
    });

    if (req.status === 200) {
      setUser(req.data);
    }
    setLoading(false);
    setOpen(false);
  };

  const disabled = loading || !name || !email;

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<Edit />}
        color="primary"
        onClick={() => setOpen(true)}
      >
        Edit Profile
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent className="pt-2">
          <TextField
            size="small"
            label="Name"
            variant="outlined"
            fullWidth
            className="mb-4"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            label="Email"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleSave()}
            disabled={disabled}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
